import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function MainPage() {
    const [formData, setFormData] = useState({
        Datum: '',
        Uhrzeit: '',
        Stichwort: '',
        Adresse: '',
        Meldung: '',
        Passwort: ''
    });

    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { Datum, Uhrzeit } = formData;
        const selectedDateTime = new Date(`${Datum}T${Uhrzeit}`);
        const currentDateTime = new Date();

        const timeDifference = currentDateTime - selectedDateTime;

        // 48 Stunden in Millisekunden (48 Stunden * 60 Minuten * 60 Sekunden * 1000 Millisekunden)
        const maxTimeDifference = 48 * 60 * 60 * 1000;

        // Überprüfen, ob die Zeitdifferenz größer als 48 Stunden ist
        if (timeDifference <= maxTimeDifference) {
            setResponseMessage('Datum und Uhrzeit dürfen nicht jünger als 48 Stunden sein.');
            return; // Keine API-Anfrage, wenn Zeitdifferenz kleiner oder gleich 48 Stunden ist
        }

        try {
            const response = await axios.post('https://einsatz-api/upload-csv', formData);
            setResponseMessage(response.data.message || 'Daten erfolgreich gesendet!');
        } catch (error) {
            setResponseMessage(`Fehler beim Senden der Daten!: ${error.response.data.error}`);
        }
    };

    return (
        <div className="container mt-3">
            <h1 className="text-center mb-4">Einsatz hinzufügen</h1>
            <form onSubmit={handleSubmit} className="p-4 shadow rounded bg-light">
                <div className="form-group mb-3">
                    <label>Datum:</label>
                    {/* Hier kann der Benutzer das Datum eingeben oder aus dem Kalender auswählen */}
                    <input
                        type="date"
                        name="Datum"
                        className="form-control"
                        value={formData.Datum}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <label>Uhrzeit:</label>
                    {/* Hier kann der Benutzer die Uhrzeit eingeben oder aus der Uhr-Auswahl wählen */}
                    <input
                        type="time"
                        name="Uhrzeit"
                        className="form-control"
                        value={formData.Uhrzeit}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <label>Stichwort:</label>
                    <input
                        type="text"
                        name="Stichwort"
                        className="form-control"
                        value={formData.Stichwort}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <label>Adresse:</label>
                    <input
                        type="text"
                        name="Adresse"
                        className="form-control"
                        value={formData.Adresse}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group mb-3">
                    <label>Meldung:</label>
                    <input
                        type='text'
                        name="Meldung"
                        className="form-control"
                        value={formData.Meldung}
                        onChange={handleChange}
                    ></input>
                </div>

                <div className="form-group mb-3">
                    <label>Passwort:</label>
                    <input
                        type="password"
                        name="Passwort"
                        className="form-control"
                        value={formData.Passwort}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="btn btn-primary w-100">Senden</button>
            </form>

            {responseMessage && (
                <div className="alert alert-info mt-4 text-center">{responseMessage}</div>
            )}
        </div>
    );
}

export default MainPage;
